import { OrgSubscriptionType } from '@/web-types';
import { MembershipPermission, ALL_PERMISSIONS } from './AllRoles';

export const AllOrgSubscriptionTypes: { [name in OrgSubscriptionType]: Set<MembershipPermission> } = {
  FULL: new Set(ALL_PERMISSIONS),
  LITE: new Set([
    MembershipPermission.FEEDING_READ,
    MembershipPermission.FEED_INVENTORY_READ,
    MembershipPermission.LOTS_READ,
    MembershipPermission.PENS_READ,
    MembershipPermission.SETTINGS_PAGE_VIEW, // for logout
  ]),
};
