import { MembershipRole, OrgSubscriptionType } from '@/web-types';
import { AllRoles, MembershipPermission } from './AllRoles';
import { isPresent } from 'ts-is-present';
import { AllOrgSubscriptionTypes } from './AllOrgSubscriptionTypes';

/** Return true is the role has the given permission */
export const checkPermission = (
  subscriptionType: OrgSubscriptionType,
  role: MembershipRole | null,
  permission: MembershipPermission | null | undefined
) => {
  return (
    isPresent(role) &&
    isPresent(permission) &&
    AllOrgSubscriptionTypes[subscriptionType].has(permission) &&
    AllRoles[role].has(permission)
  );
};

/** Return true if the role has any of the given permissions */
export const checkAnyPermission = (
  subscriptionType: OrgSubscriptionType,
  role: MembershipRole | null,
  permissions: MembershipPermission[]
) => {
  return permissions.some((permission) => checkPermission(subscriptionType, role, permission));
};
